/* eslint-disable prettier/prettier */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { graphql } from "gatsby"
import { BaseStyles, jsx } from "theme-ui"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import Metadata from "../components/Metadata"

// components
import Hero from "../components/Hero"
import Footnote from "../components/page-blocks/Footnote"
import FinanceProductsList from "../components/page-blocks/FinanceProductsList"
import BlueTransparentBlock from "../components/page-blocks/BlueTransparentBlock"
import Title from "../components/page-blocks/Title"
import BorrowersSponsorsBrokers from "../components/page-blocks/BorrowersSponsorsBrokers"
import HomePageHero from "../components/HomePageHero"
import MarkdownBlock from "../components/page-blocks/MarkdownBlock"
import OpenPositionsList from "../components/page-blocks/OpenPositionsList"
import OpenPositionsSection from "../components/careers-sections"
import TwoColumnBlock from "../components/page-blocks/TwoColumnBlock"
import Map from "../components/page-blocks/Map"
import BlueTransparentBlockWithLink from "../components/page-blocks/BlueTransparentBlockWithLink"
import LeadershipList from "../components/LeadershipList"
import FeaturedNewsArticles from "../components/page-blocks/FeaturedNewsArticles"
import SingleButton from "../components/page-blocks/SingleButton"
import FeaturedTransactions from "../components/page-blocks/FeaturedTransactions"
import ResourcesSection from "../components/page-blocks/ResourcesSection"
import ResourcesSectionBySlug from "../components/page-blocks/ResourcesSectionBySlug"
import OneColumnBlock from "../components/page-blocks/OneColumnBlock"
import VideosSection from "../components/page-blocks/VideosSection"
import PopupNote from "../components/note"
import ModalLink from "../components/home-link-modal"

const CarrersTemplate = ({ data }) => {
  const d = data.markdownRemark.frontmatter
  return (
    <Layout>
      <Metadata title={d.browserTitle} description={d.metaDescription} />
      <BaseStyles>
        {d.homePageHero && (
          <HomePageHero text={d.homePageHero} scrollTo="#section1" />
        )}
        {d.homePageHero && <div id="section1" />}{" "}
        {/* this is the anchor for the smooth scrolling on the home page hero */}
        {d.hero && d.hero.isHero && (
          <Hero
            headline={d.hero.heroHeadline}
            description={d.hero.heroDescription}
          />
        )}



        {d.markdownBlock && <MarkdownBlock content={d.markdownBlock} />}
        {d.contentBlocks &&
          d.contentBlocks.map((contentBlock, i) => (
            <div key={i}>
              {contentBlock.type === "footnote" && (
                <Footnote text={contentBlock.footnote} />
              )}

              {contentBlock.type === "blueTransparentBlock" && (
                <div>
                <BlueTransparentBlock
                  content={contentBlock.blueTransparentBlockText}
                  isExtraWide={contentBlock.isExtraWide}
                />
                
                </div>
              )}
              { contentBlock.popup && (
                <div>
                <PopupNote popupCopy={contentBlock.popupCopy}/>
                </div>
              )}


              {contentBlock.type === "blueTransparentBlockWithLink" && (
                <BlueTransparentBlockWithLink content={contentBlock} />
              )}
              {contentBlock.type === "title" && (
                <Title title={contentBlock.title} />
              )}

              {contentBlock.type === "openPositions" && <OpenPositionsSection />}
              
  
              {contentBlock.type === "twoColumnBlock" && (
                <TwoColumnBlock content={contentBlock} />
              )}
   
              {contentBlock.type === "markdownBlock" && (
                <MarkdownBlock content={contentBlock.markdownBlock} />
              )}
              {contentBlock.type === "honorsAwards" && (
                <ResourcesSectionBySlug
                  resources={contentBlock.featuredHonorsAwards}
                />
              )}                                             
              {contentBlock.type === "resourcesSection" && <ResourcesSection />}
              {contentBlock.type === "oneColumnBlock" && (
                <OneColumnBlock content={contentBlock.oneColumnBlock} />
              )}
            </div>
          ))}
      </BaseStyles>
    </Layout>
  )
}

export default CarrersTemplate

CarrersTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        browserTitle
        metaDescription
        path
        homePageHero
        hero {
          isHero
          heroHeadline
          heroDescription
        }
        markdownBlock
        contentBlocks {
          type
          footnote
          title
          markdownBlock
          buttonVariant
          blueTransparentBlockText
          isExtraWide
          popup
          popupCopy

          oneColumnBlock
          featuredHonorsAwards {
            articleSlug
          }               
        }
      }
    }
  }
`
