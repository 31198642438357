/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */

import { useStaticQuery, graphql, Link } from "gatsby"
import { jsx } from "theme-ui"
import globalStyles from "../gatsby-plugin-theme-ui/index"
import Button from "./Button"
import OpenPositionsList from "./page-blocks/OpenPositionList-2.js"
import ComponentContainer from "./ComponentContainer"
import Title from "./page-blocks/Title"


const OpenPositionsSection = () => {
  const positions = useStaticQuery(graphql`

    query {
      allMarkdownRemark(
        filter: { frontmatter: { dataType: { eq: "open-position" } } }
      ) {
        edges {
          node {
            frontmatter {
              dataType
              openPositionTitle
              openPositionPosted(formatString: "YYYY.MM.DD")
              category
              openPositionRequiredExperience
              openPositionDescription
              employmentType
              salary
              openPositionFullListing
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)  
  const pos = positions.allMarkdownRemark.edges.sort((a, b) =>
    a.node.frontmatter.openPositionPosted < b.node.frontmatter.openPositionPosted ? 1 : -1
  )


  const categories = [
    'Asset Management',
    'Global Capital Raising and Investor Relations',
    'Finance / Accounting',
    'Legal',
    'Operations',
    'Originations',
    'Capital Markets, Syndications, Credit',
    'Technology'
  ]
 
  return (
    <div
      sx={{ width: "100%",}}
    >
      <div sx={mainContainer} id="categoriesNav">
        <div sx={columnContainerNav}>
          <h2  sx={headerContainer}>Open Positions</h2>
          <ul sx={nav}>
          {categories.map((cat, i) => {
            const res = cat.replace(/ /g, '')
            return (
            <li><a  sx={navLink} href={`#${res}`}>{cat}</a></li>
            )
          })}
          </ul>
        </div>
      </div>
      <div sx={{ width: "100%", backgroundColor: "white", paddingBottom: "100px" }}>
      {categories.map((cat, i) => {
            const res = cat.replace(/ /g, '');
            var openPos;
        return (
          <div key={i} id={res}>
            <Title title={cat} />
            <ComponentContainer backgroundColor="white"  style={{ width: "100%" }}>
              <div sx={columnContainer}>
                {pos.map((t, j) => {
                  if (t.node.frontmatter.category === cat) {
                    openPos = t.node.frontmatter.category;
                    return (
                      <OpenPositionsList pos={t} key={j} />
                    )
                  }
                })}

                <p>{openPos ? null:  `No listings at this time`}</p>
                <div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    text="Back To Categories"
                    link="#categoriesNav"
                    buttonVariant="blueButton"
                  /> 
                </div>                 
              </div>
            </ComponentContainer>
          </div>
        )
      })}
      </div>
    </div>
  )
}

export default OpenPositionsSection

// styles
const mainContainer = {
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: "#009dd2d9",
  color: "white",
  justifyContent: "center",
  paddingBottom: "50px",
  paddingTop: "50px",
  fontSize: "32px",
  fontWeight: 400,  
}
const columnContainer = {
  marginTop: "0px",
  maxWidth: "800px",
  display: "flex",
  flexDirection: "column",
  marginLeft: "10px",
  marginRight: "10px",
  width: '100%',
}
const columnContainerNav = {
  marginTop: "0px",
  maxWidth: "800px",
  display: "flex",
  flexDirection: "column",
  marginLeft: "10px",
  marginRight: "10px",
  alignItems: "center",
}
const headerContainer = {
  fontSize: '32px',
  fontWeight: 'normal',
  marginTop: '0',
  marginBottom: '10px',
  textTransform: 'uppercase',
}
const title = {
  fontSize: "32px",
  fontWeight: 300,
  color: "black",
  "&:hover": {
    color: globalStyles.colors.primary,
    cursor: "pointer",
  },
}
const experience = {
  fontSize: "23px",
  color: "primary",
  marginTop: "10px",
  marginBottom: "10px",
}
const nav = {
  listStyleType: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
}
const backButton = {
  alignSelf: 'end',
}
const navLink = {
  fontSize: '16px',
  fontWeight: 'normal',
  marginRight: '26px',
  marginBottom: '8px',
  padding: '6px 12px',
  color: '#fff !important',
  border: '1px solid #fff',
  display: 'inline-block',
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "primary",
  },  
}
