/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */

import { useStaticQuery, graphql, Link } from "gatsby"
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import globalStyles from "../../gatsby-plugin-theme-ui/index"
import Button from "../Button"

const OpenPositionsList = ({pos}) => {
  const position = pos.node.frontmatter
  const positionLink = pos.node.fields.slug

  return (
    <div
      sx={{ width: "100%", backgroundColor: "white", paddingBottom: "20px" }}
    >

          <div sx={mainContainer}>
            <div sx={columnContainer}>
              <Link to={positionLink} sx={{ textDecoration: "none" }}>
                <div sx={title}>{position.openPositionTitle}</div>
              </Link>
              <div sx={experience}>
                {position.openPositionRequiredExperience}
              </div>
              <div sx={{ fontSize: "17px", color: "black" }}>
                {position.openPositionDescription}
              </div>

              <Button
                text="View Position Description"
                link={positionLink}
                buttonVariant="grayButton"
              />
            </div>
          </div>
    </div>
  )
}
OpenPositionsList.propTypes = {
  pos: PropTypes.shape({
    node: PropTypes.shape({
      frontmatter: PropTypes.shape({
        openPositionTitle: PropTypes.string.isRequired,
        openPositionPosted: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        openPositionRequiredExperience: PropTypes.string,
        openPositionDescription: PropTypes.string,
        employmentType: PropTypes.string,
        salary: PropTypes.string,
        openPositionFullListing: PropTypes.string,
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }),
  }),
}

OpenPositionsList.defaultProps = {
  pos: null,
}

export default OpenPositionsList

// styles
const mainContainer = {
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
}
const columnContainer = {
  marginTop: "10px",
  maxWidth: "800px",
  display: "flex",
  flexDirection: "column",
  marginLeft: "10px",
  marginRight: "10px",
}
const title = {
  fontSize: "32px",
  fontWeight: 300,
  color: "black",
  "&:hover": {
    color: globalStyles.colors.primary,
    cursor: "pointer",
  },
}
const experience = {
  fontSize: "23px",
  color: "primary",
  marginTop: "10px",
  marginBottom: "10px",
}
const nav = {
  listStyleType: 'none',
  display: 'flex',
  flexWrap: 'wrap',
}
const category = {
  paddingRight: '20px',
}
